.menuWrapper {
	--border-style: var(--navigation-border-width) var(--navigation-border-style) var(--navigation-color-border);
	height: 100%;
	position: relative;
}

.menuWrapperBackground {
	background-color: var(--navigation-color-background);
	content: "";
	display: none;
	height: 0;
	position: absolute;
	top: 100%;
	width: 100%;
	z-index: -1;
}

.menuWrapperBackgroundOpen {
	display: block;
	transition: height 0.4s ease-in-out;
}

.menuSectionsList {
	columns: 2;
	display: flex;
	flex-direction: column;
	height: 100%;
	list-style: none;
	margin-left: 0;
	padding: var(--spacing-xs) var(--spacing-s);

	@media (--bp-large) {
		align-items: center;
		flex-direction: row;
		justify-content: center;
		padding: 0;
	}
}

.menuSectionsListItem {

	/* Ensure the Kebab icon is not visible by default. */
	& :global(svg.is-more-button) {
		display: none;
	}
}

.menuSectionsListItemActive {

	& .menuSectionsListItemLink {
		text-decoration: none;

		@media (--bp-large) {

			& + button svg {
				transform: rotate(180deg);
			}

			&::after {
				opacity: 1;
				transform: scaleX(1);
			}
		}
	}

	& .menuSectionsListItemSubmenu {
		animation: slideInMenu 0.4s ease-in-out 0.3s forwards;

		@media (--bp-large) {
			animation-name: slideDownMenu;
		}
	}
}

.menuSectionsListItemLink {
	--color: var(--navigation-color-link);
	--font-family: var(--navigation-typography-link-l1-family);
	--font-size: var(--navigation-typography-link-l1-size);
	--font-weight: var(--navigation-typography-link-l1-weight);
	--letter-spacing: var(--navigation-typography-link-l1-letter-spacing);
	--line-height: var(--navigation-typography-link-l1-line-height);

	align-items: center;
	appearance: none;
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	gap: var(--spacing-4xs);
	line-height: 1;
	padding: var(--spacing-xs) 0;
	text-decoration: none;
	text-transform: uppercase;
	width: 100%;
	z-index: 2;

	@media (--bp-large) {
		--font-size: var(--navigation-typography-link-l1-desktop-size);
		--font-weight: var(--navigation-typography-link-l1-desktop-weight);
		--letter-spacing: var(--navigation-typography-link-l1-letter-spacing);
		--line-height: var(--navigation-typography-link-l1-line-height);
	}

	& + button svg {
		transform: rotate(-90deg);
		transition: transform 0.4s ease-in-out;

		@media (--bp-large) {
			height: 9px;
			width: 9px;
		}
	}

	&::after {
		background-color: var(--btn-primary-background);
		bottom: -1px;
		content: "";
		display: block;
		height: 3px;
		left: var(--spacing-3xs);
		opacity: 0;
		position: absolute;
		transform: scaleX(0);
		transform-origin: center;
		transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
		transition-delay: 0.1s;
		width: calc(100% - var(--spacing-3xs) * 2);
	}

	&:focus + button {
		color: var(--color-focus);
	}

	&:hover {
		color: var(--navigation-color-link);
	}

	@media (--bp-large) {
		padding: var(--spacing-xs-desktop) 0;

		&:hover::after {
			opacity: 1;
			transform: scaleX(1);
		}

		& + button svg {
			transform: rotate(0);
		}
	}
}

span.menuSectionsListItemLink {
	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
}

/*
 * This class is used to hide pseudo elements if is "no more button".
 */
.menuSectionsListItemIsMoreButton {

	& .menuSectionsListItemLinkWrapper {

		@media (--bp-large) {

			& :global(svg.is-more-button) {
				display: block;
			}

			/* Hide the Caret if "no more button" is Active */
			& :global(svg.is-more-button) + svg {
				display: none;
			}

			& button {
				cursor: pointer;
			}
		}
	}

	& :global(.is-more-button) {
		transform: none !important;
	}

	& .menuSectionsListItemLink {

		&::after {
			content: none;
		}
	}
}

.menuWrapperSubmenuActive .menuSectionsListItemLink::after {
	transition: opacity 0.4s ease-in-out;
}

.menuSectionsListItemLinkIcon {
	display: block;
	height: 16px;
	margin-right: var(--spacing-4xs);
	object-fit: contain;
	width: 16px;
}

.menuSectionsListItemLinkWrapper {
	align-items: baseline;
	column-gap: var(--spacing-4xs);
	display: flex;
	position: relative;

	& button {
		appearance: none;
		background-color: transparent;
		border: none;
		color: var(--color); /* Explicitly set color for iOS Safari */
		cursor: pointer;
		display: inline-flex;
		padding: 0;

		&::after {
			content: "";
			height: 100%;
			inset: 0;
			position: absolute;
			width: 100%;
			z-index: 2;
		}
	}

	@media (--bp-large) {
		padding-inline: var(--spacing-3xs);

		& button::after {
			content: none;
		}
	}
}

.menuSectionsListItemSubmenu {
	background-color: var(--navigation-color-background);
	display: flex;
	flex-direction: column;
	height: 100%;
	left: 0;
	overflow-y: auto;
	padding: var(--spacing-xs) 0;
	position: absolute;
	top: 0;
	transform: translateX(100%);
	visibility: hidden;
	width: 100%;
	z-index: 3;

	@media (--bp-large) {
		flex-direction: row;
		gap: var(--grid-l-gutter);
		height: auto;
		justify-content: center;
		margin-top: 1px;
		opacity: 0;
		padding: var(--spacing-l-desktop);
		top: auto;
		transform: translateY(-20px);
		transform-origin: top;
		width: calc(100vw - var(--scroll-bar));
		z-index: 1;
	}

	& .menuSectionsListItemLink {
		color: var(--color);
		font-family: var(--font-family);
		font-size: var(--font-size);
		font-weight: var(--font-weight);
		gap: var(--spacing-2xs);
		justify-content: flex-start;
		letter-spacing: var(--letter-spacing);
		line-height: var(--line-height);
		padding: var(--spacing-xs) var(--spacing-s);
		text-decoration: none;

		@media (--bp-large) {
			display: none;
			padding: 0;
		}
	}

	& .menuSectionsListItemLink svg {
		height: 12px;
		transform: rotate(180deg);
		width: 12px;
	}
}

.menuWrapperSubmenuActive .menuSectionsListItemSubmenu {
	background-color: transparent;
}

.menuWrapperSubmenuActive .menuSectionsListItem:not(.menuSectionsListItemActive) .menuSectionsListItemSubmenu {
	animation-direction: reverse;
}

.menuSectionsListItemSubmenuContainer {

	@media (--bp-large) {
		display: flex;
		gap: var(--spacing-s-desktop);
	}

	& a {
		--font-family: var(--navigation-typography-link-l2-family);
		--font-size: var(--navigation-typography-link-l2-size);
		--font-weight: var(--navigation-typography-link-l2-weight);
		--letter-spacing: var(--navigation-typography-link-l2-letter-spacing);
		--line-height: var(--navigation-typography-link-l2-line-height);

		color: var(--navigation-color-link);
		display: block;
		padding: var(--spacing-2xs) var(--spacing-2xl);
		text-decoration: none;

		@media (--bp-large) {
			--font-size: var(--navigation-typography-link-l2-desktop-size);
			--font-weight: var(--navigation-typography-link-l2-desktop-weight);
			--letter-spacing: var(--navigation-typography-link-l2-desktop-letter-spacing);
			--line-height: var(--navigation-typography-link-l2-desktop-line-height);

			padding: 0;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	& > div {
		display: flex;
		flex-direction: column;

		@media (--bp-large) {
			gap: var(--spacing-s-desktop);
			min-width: 200px;
		}
	}
}

.menuSectionsListItemSubmenuFeatured {
	border-top: var(--border-style);
	display: flex;
	flex-direction: column;
	gap: var(--spacing-m);
	margin-block-start: 1.25rem;
	padding: var(--spacing-s);
	width: 100%;

	@media (--bp-large) {
		border-top: none;
		flex: 0 1 438px;
		margin-block-start: 0;
		padding: 0;
		width: auto;
	}
}

.menuSectionsListItemSubmenuFeaturedMargin {
	padding-top: 40.31px;
}

.menuSectionsListItemSubmenuHeading {
	--font-family: var(--typography-body-family);
	--font-size: 0.75rem;
	--font-weight: var(--navigation-typography-link-l1-weight);
	--line-height: 1.36;

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: 0.005em;
	line-height: var(--line-height);
	text-transform: uppercase;
}

@keyframes slideDownMenu {

	from {
		opacity: 0;
		transform: translateY(-20px);
		visibility: hidden;
	}

	to {
		opacity: 1;
		transform: translateY(0);
		visibility: visible;
	}
}

@keyframes slideInMenu {

	from {
		transform: translateX(100%);
		visibility: hidden;
	}

	to {
		transform: translateX(0);
		visibility: visible;
	}
}
